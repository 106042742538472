var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ExportBourseAuxLivresComponent',{on:{"export":function (ref) {
      var anneeScolaire = ref.anneeScolaire;
      var cl = ref.cl;
 
      _vm.downloadingFile = {
        url: '/api/bourseAuxLivres',
        params: { 
          perimetreId: cl.id, 
          anneeScolaireId: anneeScolaire.id 
        }
      }
    }}}),_c('Downloader',{attrs:{"downloading-file":_vm.downloadingFile,"label":"export Bourse aux Livres"},on:{"downloadSuccess":function () { return _vm.downloadingFile = null; }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }